<template>
  <div id="heatMapWrapper">
    <v-app style=" scroll-behavior: smooth !important;" class="desktopLayoutClass" id="heatMap">
        <div>
            <v-row justify="center">
                <v-dialog v-model="others_alert" persistent max-width="290">
                    <v-card>
                        <v-card-title style="word-break:normal">
                            <p>{{ $t("Warning_Message.Invalid_Dependent") }}</p>
                        </v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" depressed @click="closeDependentError" style="background-color: #1467BF;">
                                {{$t("Warning_Message.Ok")}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>

        <div>
            <v-row justify="center">
                <v-dialog v-model="dependent_phone_error" persistent max-width="290">
                <v-card>
                    <v-card-title style="word-break:normal">
                    {{ $t("Warning_Message.Invalid_Phonenumber") }}
                    </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" depressed @click="closePhoneNumberModal" style="background-color: #1467BF;">
                        {{$t("Warning_Message.Ok")}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>

        <!-- start files not support -->
        <div>
            <v-row justify="center">
                <v-dialog v-model="file_type_ext" persistent max-width="290">
                <v-card>
                    <v-card-title style="word-break: normal">
                        File type not supported
                    </v-card-title>
                    <br>
                    <v-card-subtitle>Supported file formats pdf, jpeg, png</v-card-subtitle>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closeFileExt"
                    >
                        {{$t("Warning_Message.Ok")}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- end files not support -->


        <div>
          <v-row justify="center">
            <v-dialog v-model="error_template" persistent max-width="290">
              <v-card>
                  <v-card-title style="word-break:normal">
                  {{ error_message }}
                  </v-card-title>
                  <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" text @click="closeErrorTemplate" style="background-color: #1467BF;">
                      {{$t("Warning_Message.Ok")}}
                  </v-btn>
                  </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </div>


        <!-- start age dialog -->
        <div>
            <v-row justify="center">
                <v-dialog v-model="ageLimitModal" persistent max-width="290">
                <v-card>
                    <v-card-title style="word-break:normal">
                        {{$t("Warning_Message.Invalid_Dependent_Please_Add_Them_Through_My_Linked_Contacts")}}
                    </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" depressed @click="closeageLimitModal" style="background-color: #1467BF;">
                        {{$t("Warning_Message.Ok")}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>
        <!-- end age dialog -->

        <!-- start upload files -->
        <div>
            <v-dialog v-model="fileUploadModal" content-class="bottomTest" transition="dialog-bottom-transition" style="background-color: #00000050 !important;" >
                <v-card style="border-radius: 30px 30px 0px 0px !important;">
                <v-card-title class="fileUploadPopUpClass">
                    <div>
                    <p style="color: #1467BF;font-weight:bold;margin-top:10px" >
                        {{$t("Customer.BookAppointment.Upload_Files")}}

                        <!-- Upload Files -->
                        </p>

                    </div>
                    <div>
                    <v-btn v-if="fileSaveCloseBtnFlag" icon @click="fileUploadModal=false, overlay=false">
                    <img src="https://s3iconimages.mymedicine.com.mm/popupClose.svg" >
                    </v-btn>
                    </div>
                </v-card-title>
                <v-card-text align="left" style="font-weight: bolder; font-size:12px">{{$t("Customer.BookAppointment.Maximum_Limit_Allowed_For_Single_File_Is_10MB")}}</v-card-text>
                <v-card-actions>
                    <v-file-input
                    v-if="$i18n.locale=='en'"
                    v-model="uploadedFile"
                    solo
                    prepend-icon=""
                    :placeholder="Upload_File"
                    @change="fileInputChange"
                    :rules="Filerulesen"
                    accept="image/jpeg,image/png,image/webp,application/pdf,application/msword,text/csv"
                    >
                    </v-file-input>
                    <v-file-input
                        v-if="$i18n.locale=='mm'"
                        v-model="uploadedFile"
                        solo
                        prepend-icon=""
                        :placeholder="Upload_File"
                        @change="fileInputChange"
                        :rules="Filerulesmm"
                        accept="image/jpeg,image/png,	image/webp,application/pdf,	application/msword,text/csv"
                    >
                    </v-file-input>
                </v-card-actions>
                <div style="overflow:scroll; height:400px;">
                  <div v-for="file in sampleFileLists" :key="file.name" class="uploadFileList" >
                      <div style="disaply:flex; flex-direction:row;justify-content: space-between;">
                      <!-- .split("#@#")[1] -->
                      <p class="fileNameClass"> {{file.name.split("#@#")[1]}}
                      <span @click="deleteFileUploaded(file.name)" style="font-weight:bold; color:red; padding:2px" v-if="file.uploadStatus != 'Uploading'">
                      <img src="https://s3iconimages.mymedicine.com.mm/closeBtn.svg">
                      </span>
                      </p>
                      </div>
                      <div>
                      <div v-if="file.uploadStatus == 'Uploading'">
                      <p class="fileStatusUploadingClass"> {{ $t("Customer.BookAppointment.Uploading_Status") }}
                          <span class="dots-cont"> <span class="dot dot-1"></span> <span class="dot dot-2"></span> <span class="dot dot-3"></span> </span>
                      </p>
                      </div>
                      <div v-else-if="file.uploadStatus == 'Completed'">
                      <p class="fileStatusCompletedClass"> {{$t("Customer.BookAppointment.Completed_Status")}}
                          <span>
                          <img style="width:16px;margin:5px 0px 0px 6px" src="https://s3iconimages.mymedicine.com.mm/success_tick.svg" >
                          </span> </p>
                      </div>
                      <div v-else-if="file.uploadStatus == 'Failed'">
                      <p class="fileStatusFailedClass"> {{$t("Customer.BookAppointment.Failed_Status")}}
                          <span>
                          <img style="width:16px;margin:5px 0px 0px 6px" src="https://s3iconimages.mymedicine.com.mm/closeBtn.svg" >
                          </span> </p>
                      </div>
                      <div v-else-if="file.uploadStatus == 'LargeFile'">
                      <p class="fileStatusFailedClass"> {{$t("Customer.BookAppointment.File_Size_Too_Large")}}
                          <span>
                          <img style="width:16px;margin:5px 0px 0px 6px" src="https://s3iconimages.mymedicine.com.mm/closeBtn.svg" >
                          </span> </p>
                      </div>
                      </div>
                  </div>
                  <div class="ButtonClass">
                    <v-btn @click="saveFiles" style="position:relative; bottom: 20px;" width="80%" color="primary" :disabled="!fileSaveBtnFlag">
                    {{$t("Customer.BookAppointment.Save_Files")}}
                    <!-- Save -->
                    </v-btn>
                  </div>
                </div>
                <div>
                </div>
                </v-card>
            </v-dialog>
        </div>
        <!-- end upload files -->
        
        <div>
            <v-row justify="center">
                <v-dialog v-model="error_enable" persistent max-width="290">
                <v-card>
                    <v-card-title style="word-break: normal">
                        {{$t("Warning_Message.Something_Went_Wrong")}}
                    </v-card-title>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        @click="closeDialogError" style="background-color: #1467BF;"
                    >
                        {{$t("Warning_Message.Ok")}}
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>
            </v-row>
        </div>

        <!-- Add Dependent Dialog -->
        <div>
            <v-dialog v-model="dialog" max-width="100%">
            <v-card>
                <v-card-title style="font-size:18px; color:#1467BF;">
                {{ $t("Customer.BookAppointment.Add_Dependent") }}<v-spacer></v-spacer>
                <v-btn
                    v-on:click="dialog = false"
                    class="btnClass"
                    elevation="0"
                >
                    <span style="color: red"> <img src="https://s3iconimages.mymedicine.com.mm/popupClose.svg" > </span></v-btn
                >
                </v-card-title>

                <div style="padding: 10px 20px !important;background-color: white; overflow:scroll;">
                <p
                    style="
                    text-align: left;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                    margin-left: 10px;
                    margin-top: 7px;
                    font-weight: 600 !important;
                    "
                >
                    {{ $t("Customer.BookAppointment.Relationship") }}<span style="color:red">*</span>
                </p>
                <v-select
                    v-if="$i18n.locale=='en'"
                    :items="Relationship_list"
                    v-model="dependent_relationship"
                    placeholder="Relationship"
                    style="width: 100%;
                    border-radius: 10px;
                    margin-right: 10px;
                    margin-left: 0px;
                    margin-top: 7px;"
                    outlined
                    item-text = "en_text"
                    return-object
                    background-color="#F4F6FA"
                    :rules="relationshipRules"
                >
                </v-select>
                <v-select
                    v-if="$i18n.locale=='mm'"
                    :items="Relationship_list"
                    v-model="dependent_relationship"
                    placeholder="တော်စပ်မှု"
                    style="width:100%;
                    border-radius: 10px;
                    margin-right: 10px;
                    margin-left: 0px;
                    margin-top: 7px;"
                    outlined
                    item-text = "mm_text"
                    return-object
                    background-color="#F4F6FA"
                    :rules="relationshipRulesMM"
                >
                </v-select>
                <p
                    style="
                    text-align: left;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                    margin-left: 10px;
                    margin-top: 5px;
                    font-weight: 600 !important;
                    "
                >
                    {{ $t("Customer.BookAppointment.Name") }}<span style="color:red">*</span>
                </p>
                <v-text-field
                    v-model="dependent_name"
                    outlined
                    :placeholder="translation_enter_name"
                    style="width: 100%;
                    border-radius: 10px;
                    margin-right: 10px;
                    margin-left: 0px;
                    margin-top: 7px;"
                    background-color="#F4F6FA"
                    :rules="$store.state.locale == 'en' ? nameRules : nameRulesMM"
                />
                <p
                    style="
                    text-align: left;
                    padding-bottom: 0px;
                    margin-bottom: 7px;
                    margin-left: 10px;
                    margin-top: 7px;
                    font-weight: 600 !important;
                    "
                >
                    {{ $t("Customer.BookAppointment.Date_Of_Birth") }}<span style="color:red">*</span>
                </p>
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                    >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dependent_dob"
                        :placeholder="translation_dob"
                        background-color="#F4F6FA"
                        :rules="$store.state.locale == 'en' ? dobRules : dobRulesMM"
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        style="border-radius:8px;"
                    ></v-text-field>
                    </template>
                        <v-date-picker
                        v-model="dependent_dob"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1900-01-01"
                        @change="get_age(dependent_dob), menu= false"
                        full-width
                        ></v-date-picker>
                    </v-menu>
                    <p
                    style="
                        text-align: left;
                        padding-bottom: 0px;
                        margin-bottom: 0px;
                        margin-left: 10px;
                        margin-top: 7px;
                        font-weight: 600 !important;
                    "
                    >
                    {{ $t("Customer.BookAppointment.Gender") }}<span style="color:red">*</span>
                    </p>
                    <v-select
                    v-if="$i18n.locale=='en'"
                    :items="gender"
                    v-model="dependent_gender"
                    :rules="genderRules"
                    outlined
                    placeholder="Gender"
                    background-color="#F4F6FA"
                    item-text = "en_text"
                    return-object
                    style="width: 100%;
                        border-radius: 10px;
                        margin-right: 10px;
                        margin-left: 0px;
                        margin-top: 7px;"
                    >
                    </v-select>
                    <v-select
                    v-if="$i18n.locale=='mm'"
                    :items="gender"
                    v-model="dependent_gender"
                    :rules="genderRulesMM"
                    outlined
                    placeholder="ကျား/မ"
                    return-object
                    background-color="#F4F6FA"
                    item-text = "mm_text"
                    style="width: 100%;
                        border-radius: 10px;
                        margin-right: 10px;
                        margin-left: 0px;
                        margin-top: 7px;"
                    >
                    </v-select>
                <p
                    style="
                    text-align: left;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                    margin-left: 10px;
                    margin-top: 7px;
                    font-weight: 600 !important;
                    "
                >
                    {{ $t("Customer.BookAppointment.Email") }}
                </p>
                <v-text-field
                v-model="dependent_email"
                outlined
                :placeholder="translation_enter_email"
                style="width: 100%;
                    border-radius: 10px;
                    margin-right: 10px;
                    margin-left: 10px;
                    margin-top: 7px;"
                background-color="#F4F6FA"
                :rules="$i18n.locale=='en' ? emailRules : emailRulesMM"
                />
                    <p
                    style="
                    text-align: left;
                    padding-bottom: 0px;
                    margin-bottom: 0px;
                    margin-left: 10px;
                    margin-top: 7px;
                    font-weight: 600 !important;
                    "
                    >
                    {{ $t("Customer.BookAppointment.Phone_Number") }}
                    </p>
                    <v-text-field
                    v-model="dependent_phoneNumber"
                    outlined
                    :placeholder="translation_enter_number"
                    type="number"
                    style="width: 100%;
                        border-radius: 10px;
                        margin-right: 10px;
                        margin-left: 0px;
                        margin-top: 7px;"
                    background-color="#F4F6FA"
                    :rules = "$i18n.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM"
                    />
                    <p
                    style="
                        text-align: left;
                        padding-bottom: 0px;
                        margin-bottom: 0px;
                        margin-left: 10px;
                        margin-top: 7px;
                        font-weight: 600 !important;
                    "
                    >
                    {{ $t("Customer.BookAppointment.NRC") }}
                    </p>
                    <v-text-field
                    v-model="dependent_nrc"
                    outlined
                    :placeholder="translation_enter_nrc"
                    style="width: 100%;
                        border-radius: 10px;
                        margin-right: 10px;
                        margin-left: 0px;
                        margin-top: 7px;"
                    background-color="#F4F6FA"
                    />
                <v-row>
                    <v-col>
                    <p
                        style="
                        text-align: left;
                        padding-bottom: 0px;
                        margin-bottom: 0px;
                        margin-left: 10px;
                        margin-top:7px ;
                        font-weight: 600 !important;
                        "
                    >
                        {{ $t("Customer.BookAppointment.Height") }}
                    </p>
                    <div style="display:flex; felx-direction:row;">
                                <v-text-field
                                v-model="heightinFt"
                                type="number"
                                outlined
                                :placeholder="translation_feet"
                                :rules="$store.state.locale == 'en' ? heightRules : heightRulesMM"
                                style="width: 5%; border-radius: 10px;margin-top:10px;"
                                background-color="#F4F6FA"
                :suffix="translation_Ft"
                                />
                                <v-text-field
                                v-model="heightinIn"
                                type="number"
                                outlined
                                :placeholder="translation_inches"
                                :rules="$store.state.locale == 'en' ? heightRulesIn : heightRulesInMM"
                                style="width: 5%; border-radius: 10px;margin-top:10px;margin-left:5px"
                                background-color="#F4F6FA"
                :suffix="translation_In"
                                />
                            </div>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <p
                        style="
                        text-align: left;
                        padding-bottom: 0px;
                        margin-bottom: 0px;
                        margin-left: 10px;
                        margin-top: 7px;
                        font-weight: 600 !important;
                        "
                    >
                        {{ $t("Customer.BookAppointment.Weight") }}
                    </p>
                    <v-text-field
                        v-model="dependent_weight"
                        :placeholder="translation_in_lbs"
                        outlined
                        type="number"
                        :rules="$store.state.locale == 'en' ? weightRules : weightRulesMM"
                        background-color="#F4F6FA"
                        class="flexbox"
                        style="margin-top: 7px"
                        :suffix="translation_Lb"
                    />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <p
                        style="
                        text-align: left;
                        padding-bottom: 0px;
                        margin-bottom: 0px;
                        margin-left: 10px;
                        margin-top: 10px;
                        font-weight: 600 !important;
                        "
                    >
                        {{ $t("Customer.AddDependent.Alcoholic") }}
                    </p>
                    <v-select
                        v-if="$i18n.locale=='en'"
                        v-model="dependent_alcoholic"
                        :items="alcoholic_list"
                        item-text = "en_text"
                        outlined
                        class="flex-item"
                        background-color="#F4F6FA"
                        style="margin-top: 10px"
                        return-object
                    />
                        <v-select
                        v-if="$i18n.locale=='mm'"
                        v-model="dependent_alcoholic"
                        :items="alcoholic_list"
                        item-text = "mm_text"
                        outlined
                        class="flex-item"
                        background-color="#F4F6FA"
                        style="margin-top: 10px"
                        return-object
                    />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                    <p
                        style="
                        text-align: left;
                        padding-bottom: 0px;
                        margin-bottom: 0px;
                        margin-left: 10px;
                        margin-top: 10px;
                        font-weight: 600 !important;
                        "
                    >
                        {{ $t("Customer.AddDependent.Smoker") }}
                    </p>
                    <v-select
                    v-if="$i18n.locale=='en'"
                        v-model="dependent_smoker"
                        :items="smoker_list"
                        outlined
                        background-color="#F4F6FA"
                        class="flexbox"
                        item-text = "en_text"
                        style="margin-top: 10px"
                        return-object
                    />
                        <v-select
                        v-if="$i18n.locale=='mm'"
                        v-model="dependent_smoker"
                        :items="smoker_list"
                        outlined
                        background-color="#F4F6FA"
                        class="flexbox"
                        style="margin-top: 10px"
                        item-text = "mm_text"
                        return-object
                    />
                    </v-col>
                </v-row>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    style="
                    width: 98%;
                    height: 48px;
                    margin-top: -3vh;
                    align: center;
                    "
                    @click="saveDependentInfo"
                    :disabled="checkDisabled()"
                >
                    {{ $t("Customer.BookAppointment.Save_Dependent") }}
                </v-btn>
                </div>
                <br />
            </v-card>
            </v-dialog>
        </div>

        <!-- Header Start -->
        <div v-show="show_header" class="header" style="padding-top: 10px; padding-left: 5px;">
            <div class="d-flex" style="width: 100%">
                <div
                    style="width: 10%; padding-top: 6px"
                    align="left"
                    v-on:click="redirectToHomePage()"
                >
                    <img src="https://img.icons8.com/material-outlined/20/000000/left.png"/>
                </div>
                <div style="width: 90%" align="left">
                    <span class="headertext" style="font-family:'Inter'; font-style: normal;">{{ $t("Customer.DoctorConsultationPage.Doctor_Details") }}</span>
                </div>
            </div>
        </div>
        <!-- Header End -->
        
        <div class="pb-2" v-if="pageloading">
            <!-- Banner -->
            <!-- <v-card elevation="2" style="background-color:#1467BF;"> -->
            <div style="background-color: #DEEEFF;">
                <div class="pa-4">
                    <!-- Display Picture; Doctor Name, Specialization; Exprience; Price -->
                    <div class="d-flex align-center">
                        <!-- Display Picture -->
                        <div>
                            <v-img class="rounded-lg" height="60" width="60" :src="doctor_profile_picture"></v-img>
                        </div>

                        <!-- Doctor Name, Specialization -->
                        <div class="d-flex flex-column justify-center align-start pl-2" style="width: 100%;">
                            <!-- Doctor Name -->
                            <div class="px-1" style="width: 100%;" align="left">
                                <span class="heading16px">{{ doctorDisplayName }}</span>
                            </div>
                            <!-- Specialization -->
                            <div class="justify-start" align="left">
                                <span class="px-1 normaltext">{{ specialization_name }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="py-2 d-flex justify-space-between align-center">
                        <div class="pa-2 rounded d-flex justify-start align-center" style="width: 48%; background-color: #48ACEF;">
                            <div class="pl-3 d-flex align-center">
                                <img style="height: 20px;" src="https://s3iconimages.mymedicine.com.mm/experience_icon.svg">
                            </div>

                            <div class="pl-2 d-flex flex-column align-start">
                                <span class="heading14px" style="color: white;">{{ experience }} {{ $t("Customer.DoctorConsultationPage.Years") }}</span>
                                <span class="normaltext" style="color: white;">{{ $t("Customer.DoctorConsultationPage.Experience") }}</span>
                            </div>
                        </div>
                        
                        <div class="pa-2 rounded d-flex justify-start align-center" style="width: 48%; background-color: #48ACEF;">
                            <div class="pl-3 d-flex align-center">
                                <img style="height: 20px;" src="https://s3iconimages.mymedicine.com.mm/fee_icon.svg">
                            </div>

                            <div class="pl-2 d-flex flex-column align-start">
                                <span class="heading14px" style="color: white;">{{ total_fee }} {{ $t("Customer.DoctorConsultationPage.MMK") }}</span>
                                <span class="normaltext" style="color: white;">{{ $t("Customer.DoctorConsultationPage.Price") }}</span>
                            </div>
                        </div>
                    </div>
                    
                    <div>
                        <!-- About Heading -->
                        <div class="d-flex justify-start py-1">
                            <span class="heading16px">{{ $t("Customer.DoctorConsultationPage.About") }}</span>
                        </div>

                        <div class="d-flex flex-wrap">
                            <p class="ma-0 normaltext" style="text-align: justify;" v-if="!readActivated">
                                {{ description.slice(0,100) }}
                                <span v-if="!readActivated && description.length > 100" @click="activateReadMore"><b>..View more</b></span>
                            </p>
                            <p class="ma-0 normaltext" style="text-align: justify;" v-if="readActivated">{{ description }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- </v-card> -->
        </div>
        <v-col v-if="!pageloading">
            <v-skeleton-loader class="pa-1" type="list-item-avatar"></v-skeleton-loader>
            <v-skeleton-loader class="pa-1" type="heading"></v-skeleton-loader>
            <v-skeleton-loader class="pa-1" type="text@2, divider"></v-skeleton-loader>
            <v-skeleton-loader class="pa-1" type="heading"></v-skeleton-loader>
            <v-skeleton-loader class="pa-1 skeletonDatPicker d-flex" type="table-cell@5"></v-skeleton-loader>
            <v-skeleton-loader class="pa-1 mrg" type="heading"></v-skeleton-loader>
            <v-skeleton-loader class="pa-1 timepicker d-flex" type="table-cell@5"></v-skeleton-loader>
            <v-skeleton-loader class="pa-1 mrg" type="heading"></v-skeleton-loader>
            <v-skeleton-loader class="pa-1 timepicker d-flex" type="table-cell@5"></v-skeleton-loader>
            <v-skeleton-loader class="pa-1 mrg" type="heading"></v-skeleton-loader>
            <v-skeleton-loader class="pa-1 timepicker d-flex" type="table-cell@5"></v-skeleton-loader>
        </v-col>
        <!-- <bookingDoctors :mrg_timings="mrg_timings" :afternoon_timings="afternoon_timings" :evg_timings="evg_timings" ></bookingDoctors> -->
        <div style="overflow: scroll;">
            <div class="py-2" v-if="pageloading && !hideDates" style="background-color: #FFFFFF;"> 
                <div class="px-4 dateClass pb-2">
                    <span class="subheading">{{ $t("Customer.DoctorConsultationPage.Date") }}</span>
                </div>
                <div class="pr-4 pt-2 pb-4" style="background-color: #FFFFFF;"> 
                    <v-sheet max-width="100%">
                        <v-slide-group style="background-color: #FFFFFF;">
                            <v-slide-item class="datesClass" v-for=" (date, index) in datewise_slots" :key="index" v-slot="{ active, toggle }">
                                <v-card class="dateCard" height="70" width="20" elevation="2" justify="center"
                                    :disabled="!date.slots_available"
                                    :class="active ? 'activeClass' : 'dateCard'"
                                    @click="toggle(),gettimeslot(index)"
                                >
                                    <div class="font12" style="padding-top:2px !important">
                                        <p>{{ date.month }} </p>
                                    </div>
                                    <div class="dateStyle">
                                        <h4>{{ date.date }}</h4>
                                    </div>
                                    <div class="font13"> 
                                        <p>{{ date.day }}</p>
                                    </div>
                                </v-card>
                            </v-slide-item>
                        </v-slide-group>
                    </v-sheet>
                </div>
                <div class="px-4">
                    <v-divider class="dividerClass"></v-divider>
                </div>
            </div>

            <div v-if="pageloading && hideDates" style="background-color: #FFFFFF;"> 
                <div class="pa-2 walkinDate" style="background-color: #FFFFFF; display:flex; flex-direction:row; justify-content: center;"> 
                    {{ todayDate }} {{ Month }} {{ Year }}
                </div>
                <div style="padding-left: 5%; padding-right: 5%;">
                    <v-divider class="dividerClass"></v-divider>
                </div>
            </div>

            <div class="px-4 py-2" style="background-color:#FFFFFF;" v-show="datechoosen">
                <div class="py-2" v-if="mrg_timings && mrg_timings.length" style="display:flex; flex-direction:row; justify-content:flex-start;"> 
                    <div>
                        <img class="timingsicon"  src="https://s3iconimages.mymedicine.com.mm/morning_icon.svg"/>
                    </div>
                    <div style="padding-left:3%;">
                        <h4 class="ma-0 mrgClass">{{ $t("Customer.DoctorConsultationPage.Morning") }}</h4>
                    </div>
                </div>

                <div v-if="mrg_timings && mrg_timings.length">
                    <div class="d-flex flex-wrap align-center" style="justify-content: flex-start;">
                        <div class="pa-1" style="width: 33%" v-for="(times, index) in mrg_timings" :key="index">
                            <v-btn v-if="times.status" :class="{ active: times.status }">{{ times.display_timeslot.slice(0,8) }}</v-btn>
                            <v-btn v-else @click="chooseTiming(times)" class="timingsicon2" style="background-color:#FFFFFF !important;">{{ times.display_timeslot.slice(0,8) }}</v-btn>
                        </div>
                    </div>
                </div>

                <div class="py-2" v-if="afternoon_timings && afternoon_timings.length" style="display:flex; flex-direction:row; justify-content:flex-start;"> 
                    <div>
                        <img class="timingsicon"  src="https://s3iconimages.mymedicine.com.mm/noon_icon.svg"/>
                    </div>
                    <div style="padding-left:3%;"> 
                       <h4 class="ma-0 mrgClass" style="word-break: keep-all;">{{ $t("Customer.DoctorConsultationPage.Afternoon") }}</h4>
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-wrap align-center" style="justify-content: flex-start;">
                        <div class="pa-1" style="width: 33%" v-for="(times, index) in afternoon_timings" :key="index">
                            <v-btn v-if="times.status" :class="{ active: times.status }" >{{ times.display_timeslot.slice(0,8) }}</v-btn>
                            <v-btn class="timingsicon2" v-else @click="chooseTiming(times)" style="background-color:#FFFFFF !important;">{{ times.display_timeslot.slice(0,8) }}</v-btn>
                        </div>
                    </div>
                </div>

                <div class="py-2" v-if="evg_timings && evg_timings.length" style="display:flex; flex-direction:row; justify-content:flex-start;"> 
                    <div>  
                        <img class="timingsicon"  src="https://s3iconimages.mymedicine.com.mm/evening_icon.svg"/>
                    </div>
                    <div style="padding-left:3%;"> 
                       <h4 class="ma-0 mrgClass">{{ $t("Customer.DoctorConsultationPage.Evening") }}</h4>
                    </div>
                </div>

                <div>
                    <div class="d-flex flex-wrap align-center" style="justify-content: flex-start;">
                        <div class="pa-1" style="width: 33%" v-for="(times, index) in evg_timings" :key="index">
                            <v-btn v-if="times.status" :class="{ active: times.status }" >{{ times.display_timeslot.slice(0,8) }}</v-btn>
                            <v-btn class="timingsicon2" v-else @click="chooseTiming(times)" style="background-color:#FFFFFF !important;">{{ times.display_timeslot.slice(0,8) }}</v-btn>
                        </div>
                    </div>
                </div>
            </div>

            <div class="px-4 py-2" v-if="pageloading" id="scrollDiv">
                <div class="py-2" style="display:flex; flex-direction:row; justify-content: flex-start;">
                    <p class="ma-0 patientwho">{{ $t("Customer.DoctorConsultationPage.Patient") }}</p>
                </div>

                <div style="display:flex; flex-direction:row; justify-content: space-between;">
                    <div style="display: flex !important; flex-direction: row !important; width:85% !important;">
                        <v-select loading="isDependentLoading"
                            :items="dependent_members" style="border-radius: 8px; width:90% !important;"
                            item-value="_id"
                            height="40"
                            item-text="dependent_name"
                            :placeholder="translation_patient"
                            v-model="selected_dependent"
                            return-object
                            background-color="#F4F6FA"
                            outlined
                        ></v-select>
                    </div>
                    <div style="padding-left:1%;" id="add_dependent">
                        <span style="font-size: 26pt" @click="opendialogfun">
                            <img style="width:40px; border-radius: 8px;" src="https://s3iconimages.mymedicine.com.mm/add_book_appointment.svg"/>
                        </span>
                    </div>
                </div>

                <div class="py-2" style="margin-top:-10px; display:flex; flex-direction:row; justify-content: flex-start;">
                    <span class="patientwho">{{ $t("Customer.DoctorConsultationPage.Reason") }}</span>
                </div>
                <div class="py-1" style="margin-top:2px; display:flex; flex-direction:row; justify-content: flex-start;">
                    <v-textarea
                        outlined
                        background-color="#F4F6FA"
                        auto-grow
                        v-model="reason"
                        row-height="20"
                        :placeholder="translation_reason_placeholder"
                        :rules="[(v) => (v || '').length < 160 || $t('Customer.DoctorConsultationPage.Description_Rules') ]"
                    ></v-textarea>
                </div>

                <div style="margin-top:-10px; display:flex; flex-direction:row; justify-content: flex-start;" align="left">
                    <p class="patientwho">{{ $t("Customer.DoctorConsultationPage.Update_Medical_Records") }}</p>
                </div>

                <div class="uploadclass" @click="filePopOpen">
                    <div style="padding-top:5%;"> 
                        <img style="height:70px; width:150px;" src="https://s3iconimages.mymedicine.com.mm/documentUpload.svg"/>
                    </div>
                    <div style="display:flex; flex-direction:row; justify-content:center;">
                        <div class="pa-1" style="background-color:#F4F6FA;" v-if="count_success == 0">
                            <span class="normaltextbold">{{ $t("Customer.DoctorConsultationPage.Upload_Medical_Records_Description_Part1") }} </span>
                            <span class="normaltextbold" style="color:#1467BF;">{{ $t("Customer.DoctorConsultationPage.Upload_Medical_Records_Description_Part2") }} </span>
                            <span class="normaltextbold">{{ $t("Customer.DoctorConsultationPage.Upload_Medical_Records_Description_Part3") }}</span>
                        </div>
                        <div v-else class="fileUploadText">
                            <p class="uploadtext">{{$t("Customer.BookAppointment.Add_More_Files")}}<span style="color:#1467BF; padding-left:5px; padding-right:5px;">( {{count_success}} {{$t("Customer.BookAppointment.Files_Added" )}} )</span></p>
                        </div>
                    </div>
                </div>

                <div style="padding-top:5%; padding-bottom:5%;">
                <v-btn class="bookclass heading16px" @click="bookAppointment" :disabled="isBookSlot || !(((reason.length == 0) || (reason.length > 0 && reason.length < 160)) && customer_timing && selected_dependent)"
                    style="background-color:#48ACEF; color:#FFFFFF; height:40px; text-transform: none;">{{ $t("Customer.DoctorConsultationPage.Book") }}</v-btn>
                </div>
            </div>
        </div>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
import { thresholdFreedmanDiaconis } from "d3-array";
import bookingDoctors from "../../../components/bookingdoctors.vue"; 
import { axios_auth_instance_nurseBooking } from '../../../utils/axios_utils';
export default {
    name: "newBookingPage",
    data(){
        return{
            isSaved: false,
            dependent_phone_error: false,
            doctor_desc:"Doctors, also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine. They examine patients, review their medical history, diagnose illnesses or injuries, administer treatment, and counsel patients on their health and well-being.",
            show_header: true,
            readActivated: false,
            todayDate: "",
            Month: "",
            Year: "",
            doctor_name:"",
            specialization_name:"",
            pageloading: true,
            specialization_name_mm:"",
            specialization_id: "",
            fileUploadList:[],
            timeSlot: "",
            total_fee:"",
            reason:"",
            experience:"",
            doctor_id:"",
            doctor_profile_picture:"",
            description:"",
            walkin:"",
            hideDates: false,
            customer_name: "",
            description_mm:"",
            dependent_list:[],
            mrg_timings: [],
            afternoon_timings: [],
            evg_timings: [],
            mrgExtraDivs: 0,
            afternoonExtraDivs: 0,
            evgExtraDivs: 0,
            mrgtoggle_timings: '',
            afttoggle_timings: '',
            evgtoggle_timings: '',
            selected_dependent: "",
            translation_enter_nrc: "Enter NRC",
            translation_Ft: "ft",
            translation_In: "in",
            fileUploadModal: false,
            dependent_members: ["Loading.."],
            Relationship_list: [{"key":"parent","mm_text":"မိဘ","en_text":"Parent"},{"key":"child","mm_text":"သားသမီး","en_text":"Child"},{"key":"spouse","mm_text":"အိမ်ထောင်ဖက်","en_text":"Spouse"},{"key":"sibling","mm_text":"မောင်နှမ","en_text":"Sibling"}],
            relationshipRules : [v => !!v || 'Relationship is required'],
            relationshipRulesMM : [v => !!v || 'တော်စပ်ပုံထည့်သွင်းရန် လိုအပ်ပါသည်။'],
            translation_patient: "Patient",
            fileSaveCloseBtnFlag:true,
            uploadedFile: [],
            fileTypes:["image/jpeg","image/png","image/webp","application/pdf","application/msword","text/csv"],
            file_type_ext:false,
            Upload_File:"",
            isDependentLoading: false,
            datechoosen: false,
            dobRules : [v => !!v || 'DOB is required'],
            dobRulesMM : [v => !!v || 'မွေးနေ့ထည့်သွင်းရန် လိုအပ်ပါသည်။'],
            translation_enter_name: "Enter Name",
            translation_enter_number: "Enter Phone Number",
            dependent_relationship: "",
            dependent_name: "",
            nameRules : [v => !!v || 'Name is required'],
            nameRulesMM : [v => !!v || 'အမည်ထည့်သွင်းရန် လိုအပ်ပါသည်။'],
            menu:false,
            dependent_gender: "",
            genderRules : [v => !!v || 'Gender is required'],
            genderRulesMM : [v => !!v || 'ကျား/မထည့်သွင်းရန် လိုအပ်ပါသည်။'],
            dependent_nrc: "",
            translation_feet: "feet",
            overlay:false,
            others_alert: false,
            dependent_email : "",
            emailRules: [ 
                v => (!v || /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/g.test(v)) || 'Enter valid Email'
            ],
            emailRulesMM: [ 
                v => (!v || /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/g.test(v)) || 'Enter valid Email'
            ],
            translation_reason_placeholder: '',
            translation_enter_email: "Enter Email",
            dependent_phoneNumber: "",
            mobileNumberRules: [
                v => (!v || v.length >= 7 && v.length <= 11) || 'Mobile Number should be between 7 to 11 numbers',
                v => (!v || v >= 0) || 'Mobile number cannot be negative',
            ],
            mobileNumberRulesMM : [
                v => (!v || v.length >= 7 && v.length <= 11) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
                v => (!v || v >= 0) || 'Mobile number cannot be negative',
            ],
            heightinFt: "",
            ageLimitModal : false,
            heightRules : [v => (!v || v <= 10) || 'Height cannot be more than 10 feet',
                v => (!v || v >= 0) || 'number cannot be negative'
            ],
            heightRulesIn: [(v) => (!v || v <= 12) || "Inches cannot be Greater than 12",
                v => (!v || v >= 0) || 'number cannot be negative'
            ],
            heightRulesInMM : [v => (!v || v <= 12) || 'လက်မသည် ၁၂ လက်မအောက်ဖြစ်ရပါမည်။',
                v => (!v || v >= 0) || 'number cannot be negative'
            ],
            heightRulesMM: [
                (v) => (v && v <= 10) || "အရပ်သည် ၁၀ ပေ အောက်ဖြစ်သင့်ပါသည်။",
                v => (!v || v >= 0) || 'number cannot be negative'
            ],
            weightRules: [
                // (v) => !!v || "Weight is required",
                (v) => ((v && v.length <= 3) || v == '1000') || "Weight cannot be more than 1000 lbs",
            ],
            weightRulesMM: [
                // (v) => !!v || "Weight is required",
                (v) => ((v && v.length <= 3) || v == '1000') || "အလေးချိန်သည် ပေါင် ၁၀၀၀ ထပ်ပိုများသောပမာဏ ဖြည့်သွင်း၍မရပါ။",
            ],
            gender:  [{"key":"male","mm_text":"ကျား","en_text":"Male"},{"key":"female","mm_text":"မ","en_text":"Female"},{"key":"other","mm_text":"အခြား","en_text":"Other"}],
            translation_Lb: "lb",
            translation_inches: "inches",
            translation_in_lbs: "in lbs",
            alcoholic_list : [{"key":"non_drinker","mm_text":"အရက်လုံးဝမသောက်ပါ","en_text":"Non drinker"},{"key":"social_drinker","mm_text":"လူမှုရေးအရသောက်ပါသည်","en_text":"Social drinker"},{"key":"moderate_drinker","mm_text":"အလွန်အကြူးမသောက်ပါ","en_text":"Moderate drinker"},{"key":"alcoholic","mm_text":"အရက်စွဲနေပါသည်","en_text":"Alcoholic"}],
            smoker_list : [{"key":"yes","mm_text":"သောက်သည်","en_text":"Yes"},{"key":"no","mm_text":"မသောက်ပါ","en_text":"No"}],
            heightinIn: "",
            auth_error_enable: false,
            ageLimitNotInRange : false,
            error_enable: false,
            timeflag: 0,
            previoustimeObj: null,
            customer_timing: "",
            dependent_weight: null,
            datewise_slots:"",
            dependent_dob: "",
            date_slot: [],
            doctorDisplayName:"",
            dependent_alcoholic: "",
            lang: false,
            dependent_smoker: "",
            isBookSlot: false,
            error_template:false,
            dialog: false,
            toggle_timings: null,
            Filerulesen: [
                value => !!value || value.size > 100000 || 'File size too large. Maximum limit allowed 10mb.'
            ],
            Filerulesmm: [
                value => !!value || value.size > 1000000 || 'ဖိုင်ဆိုဒ်ကြီးလွန်းနေပါသည်၊ အများဆုံး 10MB သာ လက်ခံနိုင်ပါသည်။'
            ],
            sampleFileLists:[],
            fileSaveBtnFlag:true,
            fileType_extension:"",
            filesArray:[],
            error_message:"",
            count_success: 0,
            doctorId: "",
            bookingType: '',
            appointmentType: '',
            isdependentAllowed: false,
        }
    },
    components: {
      bookingDoctors,
    },
    methods: {
        closePhoneNumberModal() {
            this.dependent_phone_error = false;
        },
        removeByAttr(arr, attr, value) {
            let i = arr.length;
            while(i--) {
               if(arr[i] && arr[i].hasOwnProperty(attr) && (arguments.length > 2 && arr[i][attr] === value ) ){
                   arr.splice(i,1);
                }
            }
            return arr;
        },
        activateReadMore(){
            this.readActivated = true;
        },
        gettimeslot(date){
            this.mrg_timings = [];
            this.afternoon_timings = [];
            this.evg_timings = [];
            this.datechoosen = true;

            this.mrg_timings = this.datewise_slots[date]['slots'].morning.map((ele) => ({...ele, status: false}));
            this.afternoon_timings = this.datewise_slots[date]['slots'].afternoon.map((ele) => ({...ele, status: false}));
            this.evg_timings = this.datewise_slots[date]['slots'].evening.map((ele) => ({...ele, status: false}));

            this.customer_timing = '';
            this.dateOfAppointment = date;
        },
        redirectToHomePage() {
          this.$router.go(-1);
        },
        checkDisabled() {
            return (this.isSaved || !(this.dependent_relationship != '' && this.dependent_name != ''  && this.dependent_gender !='' && this.dependent_dob !='' && (!this.dependent_phoneNumber || this.dependent_phoneNumber.length >=7 && this.dependent_phoneNumber.length <= 11) && (!this.dependent_email || (/[a-z0-9]+@[a-z]+\.[a-z]{2,3}/g.test(this.dependent_email))) && (!this.heightinFt || this.heightinFt >= 0 && this.heightinFt <= 10) && (!this.heightinIn || this.heightinIn >= 0 && this.heightinIn <= 12) && (!this.dependent_weight || this.dependent_weight >=0 && this.dependent_weight <= 1000) ) || this.ageLimitNotInRange)
        },
        chooseTiming(timeObj) {
            if(this.previoustimeObj == null){
                timeObj.status = true;
                this.previoustimeObj = timeObj;
            }
            else{
                this.previoustimeObj['status'] = false;
                timeObj.status = true;
                this.previoustimeObj = timeObj;
            }
            this.timeSlot = timeObj.time_slot;
            this.customer_timing = timeObj.display_timeslot;
            var element = document.querySelector("#scrollDiv");
            element.scrollIntoView({ behavior: 'smooth'});
        },
        opendialogfun() {
            this.dialog = true;
        },
        closeFileExt() {
            this.file_type_ext = false;
        },
        uuidv4() {
            return 'xxxxxx#@#'.replace(/[xy]/g, function(c) {
                var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        },
        closeErrorTemplate() {
            this.error_template = false;
        },
        closeDependentError() {
            this.others_alert = false;
            this.isBookSlot = false;
        },
        filePopOpen(){
            this.fileSaveCloseBtnFlag = true
            this.overlay = true
            this.fileUploadModal = true;
            this.fileSaveBtnFlag = true;
        },
        closeDialogUnauthorized() {
            this.auth_error_enable = false;
            this.$router.push({
                name: "Login",
            });
        },
        closeDialogError() {
            this.error_enable = false;
        },
        closeageLimitModal(){
            this.ageLimitModal =false;
        },
        saveFiles(){
            this.overlay = false
            this.fileUploadModal = false
        },
        get_age(dateString) {
            var today = new Date();
            var birthDate = new Date(dateString);
            var age = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
            }
            this.ageLimitNotInRange = false;

        },
        fileInputChange(){
            if(this.uploadedFile.name)
            {
                if(this.fileTypes.includes(this.uploadedFile.type))
                {
                    var slice = this.uploadedFile.slice(0,4);      // Get the first 4 bytes of a file
                    var reader = new FileReader();    // Create instance of file reader. It is asynchronous!
                    reader.readAsArrayBuffer(slice);  // Read the chunk file and return to blob
                    reader.onload =  function(e) {
                    var file_extensions = ["89504e47","25504446","ffd8ffe0",
                                            "ffd8ffe1","ffd8ffe8","504B030414000600",
                                            "d0cf11e0","0908100000060500","efbbbf",
                                            "fffe","feff","fffe0000","0000feff","0efeff"];
                    var csv_flag=0;
                    var buffer = reader.result;          // The result ArrayBuffer
                    var view = new DataView(buffer);      // Get access to the result bytes
                    var signature = view.getUint32(0, false).toString(16);  // Read 4 bytes, big-endian，return hex string
                    this.fileType_extension=signature;
                    if(this.uploadedFile.type=="text/csv")
                    {
                        csv_flag=1;
                    }
                    if(file_extensions.includes(this.fileType_extension)||csv_flag==1){
                        this.fileSaveBtnFlag = false
                        this.fileSaveCloseBtnFlag = false
                        var fileName = this.uuidv4()+this.uploadedFile.name.split(" ").join("_").toLowerCase();
                        this.sampleFileLists.push({name:fileName, uploadStatus:"Uploading", size: this.uploadedFile.size, type: this.uploadedFile.type})

                        var active_file = this.uploadedFile;
                        var getPresignedURLBody = {
                        'file_name': fileName,
                        'file_type': active_file.type,
                        "token" : this.currentCustomer,
                        "typeOfUser" : "CUSTOMER"
                        }
                        if(this.uploadedFile.size >= 10485760)
                        {
                        this.sampleFileLists.forEach(function(item){
                            if(item.name == fileName){
                            item.uploadStatus = "LargeFile"
                            }
                        });
                        var isTrue = this.sampleFileLists.every(obj => obj.uploadStatus != 'Uploading');
                        if(isTrue)
                        {
                            this.fileSaveBtnFlag = true;
                        }
                        }
                        else
                        {
                        axios.post(process.env.VUE_APP_BACKEND_URL+"/getPresignedURLWrite", getPresignedURLBody)
                        .then((getPresignedURLSuccess) =>{
                            var fields = getPresignedURLSuccess.data.data.fields;
                            var url = getPresignedURLSuccess.data.data.url;
                            var post_body = {
                            bucket: fields.bucket,
                            ...fields,
                            'Content-Type': active_file.type,
                            file: active_file
                            };
                            const formData = new FormData();
                            for(const name in post_body)
                            {
                            formData.append(name,post_body[name]);
                            }
                            axios.post(url, formData)
                            .then((imgUploadSuccess) =>{
                            // this.fileSaveBtnFlag = true
                            this.fileSaveCloseBtnFlag = true
                            var uploadedFile = this.sampleFileLists.find(obj => obj.name == fileName)
                            this.sampleFileLists.forEach(function(item){
                                if(item.name == uploadedFile.name ){
                                item.uploadStatus = "Completed"
                                this.filesArray.push(item)
                                }
                            }.bind(this))
                            var newArray = this.sampleFileLists.filter(function (el) {
                                return el.uploadStatus == "Completed"
                            });
                            this.count_success = newArray.length;

                            var isTrue = this.sampleFileLists.every(obj => obj.uploadStatus != 'Uploading');
                            if(isTrue)
                            {
                                this.fileSaveBtnFlag = true;
                            }

                            //localStorage.setItem('fileStatus', JSON.stringify(this.filesArray))
                            })
                            .catch((imgUploadFail) =>{
                            // this.fileSaveBtnFlag = true
                            this.fileSaveCloseBtnFlag = true
                            var uploadedFile = this.sampleFileLists.find(obj => obj.name == fileName)
                            this.sampleFileLists.forEach(function(item){
                                if(item.name == uploadedFile.name && item.size > 10000000 ){
                                item.uploadStatus = "LargeFile"
                                }
                                else if(item.name == uploadedFile.name) {
                                item.uploadStatus = "Failed"
                                }
                            });
                            var isTrue = this.sampleFileLists.every(obj => obj.uploadStatus != 'Uploading');
                            if(isTrue)
                            {
                                this.fileSaveBtnFlag = true;
                            }
                            });
                        })
                        .catch((getPresignedURLError) =>{
                            this.fileSaveBtnFlag = true
                            this.fileSaveCloseBtnFlag = true
                            this.error_template = true;
                            this.error_message = "Error while getting presigned body" + "\n" + getPresignedURLError;
                        });
                        }
                        this.uploadedFile=[];
                    }
                    else{
                        this.file_type_ext = true;
                    }
                    this.uploadedFile = [];
                    }.bind(this)
                }
                else{
                    this.uploadedFile = [];
                    this.file_type_ext = true;
                }
            }
        },
        deleteFileUploaded(filename){
            this.sampleFileLists.splice(this.sampleFileLists.findIndex(fileIndex => fileIndex.name == filename),1)
            var newArray = this.sampleFileLists.filter(function (el) {
                return el.uploadStatus == "Completed"
            });
            this.count_success = newArray.length;
        },
        bookAppointment() {
        // this.base64();
        if(this.selected_dependent._id != 'Others_id')
        {
            this.isBookSlot = true;
            localStorage.removeItem('BookingDetails')
            this.sampleFileLists.forEach(function(item){
            this.fileUploadList.push(item.name)
            }.bind(this))
            var booking_data = {
                    dateOfAppointment: this.dateOfAppointment,
                    amount: this.total_fee > 0 ? this.total_fee : 0,
                    specialization_display_name: this.specialization_name,
                    specialization_id: this.specialization_id,
                    bookedByName: this.customer_name,
                    bookedForId: this.selected_dependent._id,
                    bookedForName: this.selected_dependent.dependent_name,
                    displayTime: this.customer_timing,
                    status: "Booked and awaiting payment",
                    doctorDisplayName: this.doctorDisplayName,
                    description: this.reason,
                    doctorId: this.doctor_id,
                    timeSlot: this.timeSlot,
                    doctorSamaId: this.doctor_id,
                    fileUploadList:this.fileUploadList,
                    paymentStatus: "Payment Pending",
                    token: this.currentCustomer,
                    typeOfUser: "CUSTOMER",
                    bookingType: this.bookingType,
                    appointmentType: this.appointmentType
            };
            this.$router.push({
            name: "verifyAppointment",
            params: { booking_details_from_params: booking_data },
            });
        }
        else
        {
            this.others_alert = true;
        }
        },
        saveDependentInfo() {
            this.isSaved = true;
            this.dependent_height = this.heightinFt +"\'"+ this.heightinIn + "\"";
            var dependent_body = {
                // dependentCustomerMappingId: this.customer_id,
                token: this.currentCustomer,
                typeOfUser: "CUSTOMER",
                dependentName: this.dependent_name,
                dependentCustomerRelation: this.dependent_relationship.en_text,
                dependentDob: this.dependent_dob,
                dependentHeight: this.dependent_height ? this.dependent_height : '',
                dependentWeight: this.dependent_weight ? Number(this.dependent_weight) : 0,
                dependentGender: this.dependent_gender.en_text,
                dependentEmail : this.dependent_email ? this.dependent_email : '',
                dependentNrc: this.dependent_nrc ? this.dependent_nrc : '',
                dependentPhoneNumber:this.dependent_phoneNumber ? this.dependent_phoneNumber :  this.customer_name != "Guest" ? this.phoneNumber : "",
                dependentAlcoholic : this.dependent_alcoholic.en_text ? this.dependent_alcoholic.en_text : '',
                dependentSmoker : this.dependent_smoker.en_text ? this.dependent_smoker.en_text : '',                
            };
            if(this.dependent_phoneNumber)
            {
                if(this.dependent_phoneNumber.toString().length >= 7 && this.dependent_phoneNumber.toString().length <= 11)
                {
                    axios_auth_instance_nurseBooking
                    .post("/customer/dependent", dependent_body)
                    .then((saveDependentInfoResponse) => {
                        this.isSaved = false;
                        this.dialog = false;
                        var dependent_object = saveDependentInfoResponse.data;
                        this.dependent_members.push({
                            _id: dependent_object.dependentMongoID,
                            dependent_name: dependent_object.dependentName,
                        });
                        if(this.dependent_members.length > 2) {
                            this.removeByAttr(this.dependent_members, 'dependent_name','Others');
                        }
                        this.dependent_name = "";
                        this.dependent_nrc ="";
                        this.dependent_relationship = "";
                        this.dependent_dob = "";
                        this.dependent_height = "";
                        this.dependent_weight = null;
                        this.dependent_gender = "";
                        this.dependent_phoneNumber = "";
                        this.dialog = false;
                    })
                    .catch((saveDependentInfoError) => {
                        if (saveDependentInfoError.response.status == 401) {
                            this.auth_error_enable = true;
                        } else {
                            this.error_enable = true;
                            this.isSaved = false;
                        }
                        this.dialog = false;
                    });
                }
                else {
                    this.dependent_phone_error = true;
                }
            }
            else
            {
                axios_auth_instance_nurseBooking
                .post("/customer/dependent", dependent_body)
                .then((saveDependentInfoResponse) => {
                    this.dialog = false;
                    this.isSaved = false;
                    var dependent_object = saveDependentInfoResponse.data;
                    this.dependent_members.push({
                            _id: dependent_object.dependentMongoID,
                            dependent_name: dependent_object.dependentName,
                        });
                    if(this.dependent_members.length > 2) {
                        this.removeByAttr(this.dependent_members, 'dependent_name','Others');
                    }
                    this.dependent_name = "";
                    this.dependent_nrc ="";
                    this.dependent_relationship = "";
                    this.dependent_dob = "";
                    this.dependent_height = "";
                    this.dependent_weight = null;
                    this.dependent_gender = "";
                    this.dependent_phoneNumber = "";
                    this.dialog = false;
                })
                .catch((saveDependentInfoError) => {
                if (saveDependentInfoError.response.status == 401) {
                    this.auth_error_enable = true;
                } else {
                    this.error_enable = true;
                    this.isSaved = false;
                }
                this.dialog = false;
                });
            }
        },
        userPageViewTable() {
            if (this.currentCustomer === null) {
                var userPageViewTableBody = {
                pageName : 'bookingPage',
                typeOfUser: 'CUSTOMER'
                }
            } else {
                var userPageViewTableBody = {
                pageName: 'bookingPage',
                typeOfUser: 'CUSTOMER',
                token: this.currentCustomer
                }
            }
            axios.post(process.env.VUE_APP_BACKEND_URL+"/userPageViewTable", userPageViewTableBody)
            .catch((userPageViewTableError) => {
                console.log('[Error][userPageTableError] Error in adding userPageViewTable', userPageViewTableError);
            });
        },
    },
    mounted() {
    this.$el.addEventListener('click', this.onClick)
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    if(this.$store.state.locale == "en"){
      document.title = "Book an Appointment";
      this.Upload_File = "Click here to upload files";
      this.translation_reason_placeholder = "Type your reason here";
    }
    else{
      document.title = "ရက်ချိန်းရယူခြင်း";
      this.Upload_File = "ဖိုင်တင်ရန် ဒီနေရာကို နှိပ်ပါ။";
      this.translation_reason_placeholder = "ပြသလိုသည့်အကြောင်းအရာကို ဤနေရာတွင်ရေးပါ";
    }
    this.pageloading = false;
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      var slicedData = userAgent.slice(userAgent.indexOf("kbzpay")).split('/')
      if(slicedData[slicedData.length-1] < "5.2.3"){
       this.appLevelCheck = false
       this.appLevelCheckModal = true
      }
    }
    if(this.$store.state.locale !== "")
    {
      this.$i18n.locale =  this.$store.state.locale;
    }
    else
    {
      this.$i18n.locale = "mm";
      this.$store.dispatch("changeLocale", this.$i18n.locale);
    }
    this.lang = this.$i18n.locale;
    if(this.$i18n.locale == "en") {
        this.translation_enter_name = "Enter Name";
        this.translation_dob = "Enter Date Of Birth";
        this.translation_enter_email = "Enter Email";
        this.translation_enter_number = "Enter Phone Number";
        this.translation_enter_nrc = "Enter NRC";
        this.translation_in_lbs = "in lbs";
        this.translation_feet = "feet";
        this.translation_inches = "inches";
        this.translation_patient = "Patient";
        this.translation_time = "Time";
        this.translation_Ft = "ft";
        this.translation_In = "in";
        this.translation_Lb = "lb";
    } else {
        this.translation_enter_name = "အမည်ထည့်မည်";
        this.translation_dob = "မွေးနေ့";
        this.translation_enter_email = "အီးလ်မေး";
        this.translation_enter_number = "ဖုန်းနံပါတ်";
        this.translation_enter_nrc = "မှတ်ပုံတင်";
        this.translation_in_lbs = "ပေါင်";
        this.translation_feet = "ပေ";
        this.translation_inches = "လက်မ";
        this.translation_patient = "လူနာ";
        this.translation_time = "အချိန်";
        this.translation_Ft = "ပေ";
        this.translation_In = "လက်မ";
        this.translation_Lb = "ပေါင်";
    }
    this.currentCustomer = this.$cookies.get("customerToken");
    if (!this.currentCustomer) {
      this.$router.push({
        name: "Login",
      });
    } else {
      //logging page hit - start
      let x =  localStorage.getItem("currentURL");
      if(x === 'ListofSpecDoctorsScreen'){
        this.$store.dispatch("changeDependency",false);
      }
      let pageName = "BookAppointmentScreen";
      if( x == pageName)
        x = "";
      localStorage.setItem("currentURL", pageName)
      var pageAccessBody = {
        token: this.currentCustomer,
        pageName: pageName,
        previousPage: x ? x : "START"
      }
      axios.post(process.env.VUE_APP_BACKEND_URL + "/logUserAPIInvocations", pageAccessBody);
      this.doctorId = this.$route.params.docId;
      this.bookingType = this.$route.params.walkIn;
      if (this.$route.params.walkIn === 'walkin'){
        this.hideDates = true;
        localStorage.setItem('bookingType', 'walkinAppointment');
      }
      var doctorConsulationData = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER",
        doctorId: this.doctorId
      };
      this.isdependentAllowed = this.$store.state.isDependentAllowed;
      if(this.hideDates == false){
        axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/doctorConsultationPage",
          doctorConsulationData
        )
        .then((doctorConsulationPageResponse) => {
          this.doctorDisplayName = doctorConsulationPageResponse.data.data.doctor_name;
          this.customer_name = doctorConsulationPageResponse.data.data.customer_name;
          this.total_fee = doctorConsulationPageResponse.data.data.total_fee;
          this.doctor_id = doctorConsulationPageResponse.data.data.doctor_id;
          this.doctor_profile_picture = doctorConsulationPageResponse.data.data.doctor_profile_picture;
          this.specialization_name = doctorConsulationPageResponse.data.data.specialization_name;
          this.specialization_id = doctorConsulationPageResponse.data.data.specialization_id;
          this.experience = doctorConsulationPageResponse.data.data.experience;
          this.datewise_slots = doctorConsulationPageResponse.data.data.datewise_slots;
          this.description = doctorConsulationPageResponse.data.data.description;
          if (!this.$store.state.isDependentAllowed) {
            var dependent_list = [];
            var dependent_members = doctorConsulationPageResponse.data.data.dependents_data;
            dependent_members.forEach(function (item, index, object) {
                var today = new Date();
                var birthDate = new Date(item.dependent_dob);
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
                }
                dependent_list.push(item);
            });
            
            this.isDependentLoading = true;
            this.pageloading = true;
            this.dependent_members = dependent_list;
            
            if(this.customer_name == "Guest")this.dependent_members.push({
                _id: doctorConsulationPageResponse.data.data.customer_id,
                dependent_name: this.customer_name == "Guest" ? "Me" : this.customer_name,
                NRC: this.NRC,
                // phoneNumber: this.phoneNumber,
            });
            this.dependent_members.push({
                _id: doctorConsulationPageResponse.data.data.customer_id,
                dependent_name: this.customer_name == "Guest" ? "Me" : this.customer_name,
                NRC: this.NRC,
            });
            console.log(this.dependent_members);
            {
                this.dependent_members.push({
                _id: "Others_id",
                dependent_name: "Others",
                NRC: "",
                // phoneNumber: "",
                });
            }
            this.userPageViewTable();
            if(this.dependent_members.length > 2)
                this.removeByAttr(this.dependent_members, 'dependent_name','Others');
            this.isDependentLoading = false;
          } else {
            this.dependent_members = [];
            this.dependent_members.push({
                _id: doctorConsulationPageResponse.data.data.customer_id,
                dependent_name: this.customer_name == "Guest" ? "Me" : this.customer_name,
                NRC: this.NRC,
            });
            this.isDependentLoading = false;
            this.pageloading = true;
          }
            if(localStorage.getItem('showHeatMap')){
            this.drawHeatMap();
            }
        })
        .catch((doctorConsulationPageError) => {
          console.log(doctorConsulationPageError)
          this.isPageLoading = false;
        });
      } else {
        axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/walkInDoctorConsultationPage",
          doctorConsulationData
        )
        .then((doctorConsulationPageResponse) => {
            this.doctorDisplayName = doctorConsulationPageResponse.data.data.doctor_name;
            this.customer_name = doctorConsulationPageResponse.data.data.customer_name;
            this.total_fee = doctorConsulationPageResponse.data.data.total_fee;
            this.doctor_id = doctorConsulationPageResponse.data.data.doctor_id;
            this.doctor_profile_picture = doctorConsulationPageResponse.data.data.doctor_profile_picture;
            this.specialization_name = doctorConsulationPageResponse.data.data.specialization_name;
            this.specialization_id = doctorConsulationPageResponse.data.data.specialization_id;
            this.experience = doctorConsulationPageResponse.data.data.experience;
            this.datewise_slots = doctorConsulationPageResponse.data.data.slots;
            this.todayDate = doctorConsulationPageResponse.data.data.today.date;
            this.Month = doctorConsulationPageResponse.data.data.today.month;
            this.Year = doctorConsulationPageResponse.data.data.today.year;
            this.dateOfAppointment = new Date().toISOString().split('T')[0];
            this.datechoosen = true;
            this.description = doctorConsulationPageResponse.data.data.description;
          
            this.mrg_timings = this.datewise_slots.morning.map((ele) => ({...ele, status: false}));
            this.afternoon_timings = this.datewise_slots.afternoon.map((ele) => ({...ele, status: false}));
            this.evg_timings = this.datewise_slots.evening.map((ele) => ({...ele, status: false}));

            let dependent_list = [];
            let dependent_members = doctorConsulationPageResponse.data.data.dependents_data;
            dependent_members.forEach(function (item, index, object) {
                let today = new Date();
                let birthDate = new Date(item.dependent_dob);
                let age = today.getFullYear() - birthDate.getFullYear();
                let m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age--;
                }
                dependent_list.push(item);
            });
            this.isDependentLoading = true;
            this.pageloading = true;
            this.dependent_members = dependent_list;
            this.dependent_members.push({
                _id: this.customer_name,
                dependent_name: this.customer_name == "Guest" ? "Me" : this.customer_name,
                NRC: this.NRC,
                // phoneNumber: this.phoneNumber,
            });
            if(this.customer_name == "Guest")
            {
                this.dependent_members.push({
                _id: "Others_id",
                dependent_name: "Others",
                NRC: "",
                // phoneNumber: "",
                });
            }
            if(this.dependent_members.length > 2)
                this.removeByAttr(this.dependent_members, 'dependent_name','Others');
            this.isDependentLoading = false;
            if(localStorage.getItem('showHeatMap')){
                this.drawHeatMap();
            }
        })
        .catch((doctorConsulationPageError) => {
            console.log(doctorConsulationPageError);
            this.isPageLoading = false;
        });
      }
      let storedAppointmentType = localStorage.getItem('bookingType');
      if (storedAppointmentType === 'walkinAppointment') {
        this.appointmentType = 'WALKIN';
      } else if (storedAppointmentType === 'featureSpecialityAppointment') {
        this.appointmentType = 'FEATURE';
      } else if (storedAppointmentType === 'oldServiceAppointment') {
        this.appointmentType = 'SERVICE';
      } else if (storedAppointmentType === 'regularAppointment') {
        this.appointmentType = 'REGULAR';
      }
    }
  },
}
</script>
<style scoped>
.header {
  display: block;
  box-shadow: 0px 2px 15px 0px #00000026;
  padding: 16px 20px !important;
  background-color: white;
}
.ButtonClass
{
  position: fixed;
  bottom: 0;
  margin-bottom: 5%;
  width: 100%;
  padding: 0px 12px;
  max-width: 425px;
}
:deep(.bottomTest){
    height: 75%;
    align-self: flex-end;
    position: absolute;
    bottom: 0;
    margin-bottom: 0px;
}
.docdes{
    font-style: 'Inter';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    /* color: #333333; */
    text-align: justify;
    color: #FFFFFF;
    padding-right:3%;
}

.dateClass{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
}

.patientwho{
    font-family: 'Inter';
    font-style: normal;
    /* font-weight: 500; */
    font-size: 14px;
    line-height: 16px;
    color: #333333;
}

.fileUploadPopUpClass{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.fileStatusUploadingClass{
  display: flex;
  flex-direction: row;
  margin: 0% !important;
  color: #F2994A;
}

.fileStatusCompletedClass{
  display: flex;
  flex-direction: row;
  margin: 0% !important;
  color: #27AE60;
}

.fileStatusFailedClass{
  display: flex;
  flex-direction: row;
  margin: 0% !important;
  color:#EB5757;
}

.activeClass {
  background-color: #1467BF !important;
  color: white !important;
  padding: 0px !important;
  margin: 0px !important;
  box-shadow: 0px 0px 28px 0px #1366be4d !important;
}

.walkinDate{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #333333;
}

.uploadFileList{
  display: flex;
  flex-direction: column;
  background: #F4F6FA;
  border: 1px solid #E0E0E0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 90%;
  margin-left:auto;
  margin-right: auto;
  margin-top:2%;
  padding:8px
}
.uploadFileList:hover{
  background: #C9E5FF;
}

.fileNameClass{
  display: flex;
  flex-direction: row;
  margin: 0px !important;
  border-radius: 4px;
  justify-content: space-between;
}

.dividerClass{
    opacity: 0.28;
    background-color: #48ACEF;
}
.dateStyle {
  margin-top: -18px !important;
  padding: 0px !important;
  font-size: 17px !important;
  font-weight:500 !important;
}

.uploadtext{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #333333;
}

.imgattachementClass{
  width: 40px;
}

.bookclass{
    width: 100%;
    height: 48px;
    background-color: #48ACEF;
    border-radius: 5px;
}
.uploadclass{
 display:flex;
 flex-direction:column;
 justify-content:center;
 padding-top:5%;
 padding-bottom:5%;
 height:125px;
 width: 100%;
 background-color:#F4F6FA;
 border-radius:5px;
 /* margin-left:5%;
 margin-right:10%; */

}
.timingSelected{
    background-color:#FFFFFF;
}

.fileAttachmentsCard{
  margin-top: 8px;
  border-radius: 10px;
}
.dateCard {
  margin: 0px !important;
  color: black;
  padding: 0px 0px 15px 0px !important;
  border: 1px solid;
  box-shadow: unset !important;
  border-color: rgb(146, 141, 141) !important;
}

.thumbnailattachementClass{
  width: 43px;
  height: 53px;
}

.individualFile{
  width: 100px;
}

.attachementClass{
  width: 64px;
  height: 74px;
  border: #E0E0E0 solid 1px;
  padding: 10px;
  padding-bottom: 10px;
  margin: 15px;
  margin-bottom: 0px !important ;
  border-radius: 8px;
}

.attachmentsTitle{
  font-size: 16px;
  color: #333333;
  font-style: normal;
  font-weight: 600;
  padding-top: 10px;
  padding-left:10px;
}

.attachementWrapCalss{
  display: flex;
  flex-wrap: wrap;
}

.activeClass {
  background-color:#48ACEF !important; 
  color:#FFFFFF !important; 
  border: 1px solid #48ACEF !important;
  padding: 0px !important;
  margin: 0px !important;
  box-shadow: unset !important;
}

.datesClass{
    width:15% !important;
    margin-left: 16px !important;
    border-radius: 7px !important;
}

.active{
    width: 100% !important;
    height: 30px !important;
    border: 1px solid #48ACEF !important;
    background-color: #48ACEF !important;
    border-radius: 4px;
    color:#FFFFFF !important;
    font-weight:400;
    font-size:13px;
    text-align:justify;

    /* color: #48ACEF !important;
    width: 80px;
    height: 30px !important;
    background-color: #48ACEF !important;
    border: 0.5px solid #48ACEF !important;
    border-radius: 4px;
    font-size:13px;
    font-weight:400;
    text-align:justify; */
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn{
    border-color: #48ACEF !important;
}

.v-btn--is-elevated{
    box-shadow: unset !important;
}

.v-select {
    font-size: 12px;
}

.v-textarea{
    font-size: 12px;
}
.font12 {
  font-size: 12px !important;
  padding: 0px 0px !important;
  font-weight: 300 !important;
  line-height: 18px !important;
}

.mrgClass{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
}

.timingsicon{
    height: 20px;
    width: 20px;
}

.timingsicon2{
    width: 100% !important;
    height: 30px !important;
    border: 1px solid #48ACEF !important;
    background-color: #FFFFFF !important;
    border-radius: 4px;
    color:#48ACEF !important;
    font-weight:400;
    font-size:13px;
    text-align:justify;
}

.font13 {
  font-size: 14px !important;
  padding: 0px 0px !important;
  font-weight:300 !important;
  line-height: 18px;
}

</style>

<style> 
.v-text-field--enclosed .v-input__append-inner{
    margin-top:10px !important;
}
.v-text-field--outlined>.v-input__control>.v-input__slot{
    min-height: 40px !important;
}
.skeletonDatPicker .v-skeleton-loader__text {
    height: 40px !important;
    margin: 8px !important;
    width: 20px !important;
}

.timepicker .v-skeleton-loader__text {
    height: 23px !important;
    margin: 8px !important;
    width: 35px !important;
}

.mrg .v-skeleton-loader__heading {
    height: 15px !important;
    margin: 8px !important;
    width: 25% !important;
}

.headertext{
    font-size: 18px;
    font-weight: bold;
}

.heading16px{
    font-size: 14px;
    font-weight: 600;
}

.heading14px{
    font-size: 14px;
    font-weight: 600;
}

.subheading{
    font-size: 14px;
    font-weight: 400;
}

.normaltext{
    font-size: 12px;
    font-weight: 400;
}

.normaltextbold{
    font-size: 12px;
    font-weight: 600;
}
bottomTest::-webkit-scrollbar-thumb{
  display: none !important;
  background: unset !important;
  width: 0px !important;
}
bottomTest::-webkit-scrollbar-track {
  width: 0px !important;
  display: none !important;
  background: unset !important;
}
bottomTest::-webkit-scrollbar {
  width: 0px !important;
  background: unset !important;
}
</style>