<template>
    <v-app>
        <div style="padding-top:3%; padding-left:3%; padding-right:3%; background-color: #F5FAFF;"> 
            <div class="dateClass"> 
                <h3>Date</h3>
            </div>
            <div style="padding-top:2%; padding-right:3%; background-color: #F5FAFF;"> 
                <v-sheet max-width="100%" >
                    <v-slide-group style="background-color: #F5FAFF;">
                        <v-slide-item
                        class="datesClass"
                        >
                        <v-card
                            class="dateCard"
                            height="70"
                            style="background-color:#48ACEF; color:#FFFFFF; border: 1px solid #48ACEF !important;"
                            width="20"
                            elevation="2"
                            justify="center"
                            @click="toggle(), getTimeSlot(date)"
                        >
                            <div class="font12" style="padding-top:2px !important">
                                <p>Nov</p>
                            </div>
                            <div class="dateStyle">
                                <h4>07</h4>
                            </div>
                            <div class="font13"> 
                                <p>Fri</p>
                            </div>
                        </v-card>
                        </v-slide-item>
                        <v-slide-item
                        class="datesClass"
                        >
                        <v-card
                            class="dateCard"
                            height="70"
                            width="10"
                            :disabled=true
                            elevation="0"
                            justify="center"
                            @click="toggle(), getTimeSlot(date)"
                        >
                            <div class="font12" style="padding-top:2px !important">
                                <p>Nov</p>
                            </div>
                            <div class="dateStyle">
                                <h4>11</h4>
                            </div>
                            <div class="font13"> 
                                <p>Fri</p>
                            </div>
                        </v-card>
                        </v-slide-item>
                        <v-slide-item
                        class="datesClass"
                        >
                        <v-card
                            class="dateCard"
                            height="70"
                            width="10"
                            elevation="0"
                            justify="center"
                            @click="toggle(), getTimeSlot(date)"
                        >
                            <div class="font12" style="padding-top:2px !important">
                                <p>Nov</p>
                            </div>
                            <div class="dateStyle">
                                <h4>11</h4>
                            </div>
                            <div class="font13"> 
                                <p>Fri</p>
                            </div>
                        </v-card>
                        </v-slide-item>
                        <v-slide-item
                        class="datesClass"
                        >
                        <v-card
                            class="dateCard"
                            height="70"
                            width="10"
                            elevation="0"
                            justify="center"
                            @click="toggle(), getTimeSlot(date)"
                        >
                            <div class="font12" style="padding-top:2px !important">
                                <p>Nov</p>
                            </div>
                            <div class="dateStyle">
                                <h4>11</h4>
                            </div>
                            <div class="font13"> 
                                <p>Fri</p>
                            </div>
                        </v-card>
                        </v-slide-item>
                        <v-slide-item
                        class="datesClass"
                        >
                        <v-card
                            class="dateCard"
                            height="70"
                            width="10"
                            elevation="0"
                            justify="center"
                            @click="toggle(), getTimeSlot(date)"
                        >
                            <div class="font12" style="padding-top:2px !important">
                                <p>Nov</p>
                            </div>
                            <div class="dateStyle">
                                <h4>11</h4>
                            </div>
                            <div class="font13"> 
                                <p>Fri</p>
                            </div>
                        </v-card>
                        </v-slide-item>
                    </v-slide-group>
                </v-sheet>
            </div>
            <div style="padding-top:5%; padding-left:5%; padding-right:5%;">
                <v-divider class="dividerClass"></v-divider>
            </div>        
        </div>
        <div style="background-color:#F5FAFF; padding-bottom:3%; padding-top:3%;">
            <div style="padding-top:5%; padding-left:5%; padding-right:5%; display:flex; flex-direction:row; justify-content:flex-start;"> 
                <div>  
                    <img class="timingsicon"  src="@/assets/mrg_icon.svg"/>
                </div>
                <div style="padding-left:3%;"> 
                    <h4 class="mrgClass">Morning</h4>
                </div>
            </div>

            <div style="padding-top:0%; padding-left:5%;">
                <div style="display:flex; flex-direction:row; text-align: justify; flex-wrap:wrap;">
                    <div v-for=" (times, index) in mrg_timings" :key=times.time style="padding-left:3%; padding-top:2%;">
                        <v-btn v-if="times.status" v-model="toggle_timings"  @click="chooseTiming(index)" :class="{ active: times.status }"  style="background-color: #48ACEF">{{ times.time }}</v-btn>
                        <v-btn v-else @click="chooseTiming(index)" v-model="toggle_timings" class="timingsicon2" style="background-color:#FFFFFF !important;">{{ times.time }}</v-btn>
                    </div>
                </div>
            </div>

            <div style="padding-top:3%; padding-left:5%; padding-right:5%; display:flex; flex-direction:row; justify-content:flex-start;"> 
                <div>  
                    <img class="timingsicon"  src="@/assets/afternoon_icon.svg"/>
                </div>
                <div style="padding-left:3%;"> 
                    <h4 class="mrgClass">Afternoon</h4>
                </div>
            </div>

            <div style="padding-top:0%; padding-left:5%;">
                <div style="display:flex; flex-direction:row; text-align: justify; flex-wrap:wrap;">
                    <div v-for=" (times, index) in afternoon_timings" :key=times.time style="padding-left:3%; padding-top:2%;">
                        <v-btn v-if="times.status" v-model="toggle_timings"  @click="chooseTiming(index)" :class="{ active: times.status }" style="background-color: #48ACEF">{{ times.time }}</v-btn>
                        <v-btn v-else @click="chooseTiming(index)" v-model="toggle_timings" class="timingsicon2" style="background-color:#FFFFFF !important;">{{ times.time }}</v-btn>
                    </div>
                </div>
            </div>

            <div style="padding-top:3%; padding-left:5%; padding-right:5%; display:flex; flex-direction:row; justify-content:flex-start;"> 
                <div>  
                    <img class="timingsicon"  src="@/assets/evg_icon.svg"/>
                </div>
                <div style="padding-left:3%;"> 
                    <h4 class="mrgClass">Evening</h4>
                </div>
            </div>

            <div style="padding-top:0%; padding-left:5%;">
                <div style="display:flex; flex-direction:row; text-align: justify; flex-wrap:wrap;">
                    <div v-for=" (times, index) in evg_timings" :key=times.time style="padding-left:3%; padding-top:2%;">
                        <v-btn v-if="times.status" v-model="toggle_timings"  @click="chooseTiming(index)" :class="{ active: times.status }" style="background-color: #48ACEF" >{{ times.time }}</v-btn>
                        <v-btn v-else @click="chooseTiming(index)" v-model="toggle_timings" class="timingsicon2" style="background-color:#FFFFFF !important;">{{ times.time }}</v-btn>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script> 
export default {
    name: "bookingDoctors",
    data(){
        return{
            doctor_desc:"Doctors, also known as physicians, are licensed health professionals who maintain and restore human health through the practice of medicine. They examine patients, review their medical history, diagnose illnesses or injuries, administer treatment, and counsel patients on their health and well-being.",
            show_header: true,
            readActivated: false,
            props : ['mrg_timings','afternoon_timings','evg_timings'],
            mrg_timings: [ {time:'10.00 AM',status:false},
                           {time:'10.20 AM',status:false},
                           {time:'10.30 AM',status:false},
                           {time:'11.00 AM',status:false},
                           {time:'11.30 AM',status:false}
                        ],
            afternoon_timings: [ {time:'12.00 PM',status:false},
                           {time:'12.20 PM',status:false},
                           {time:'12.30 PM',status:false},
                           {time:'1.00 PM',status:false},
                           {time:'1.30 PM',status:false}
                        ],
            evg_timings: [ {time:'4.00 PM',status:false},
                           {time:'4.20 PM',status:false},
                           {time:'4.30 PM',status:false},
                           {time:'5.00 PM',status:false},
                           {time:'5.30 PM',status:false}
                        ],
            mrgtoggle_timings: '',
            selected_dependent: "",
            dependent_members: ['Gowshik','Pradeep','praveen','vicky','yashodhar'],
            translation_patient: "Patient",
            isDependentLoading: false,
            dialog: false,
            afttoggle_timings: '',
            toggle_timings: null,
        }
    },
    methods: {
        activateReadMore(){
            console.log("hi");
            this.readActivated = true;
        },
        chooseTiming(index){
            for(let i=0;i<this.mrg_timings.length;i++){
                this.mrg_timings[i].status =false;
            }
            this.mrg_timings[index].status = true;
            this.toggle_timings = index.time;
            console.log(this.mrg_timings);
        },
        opendialog(){
            this.dialog = true;
        }
    }
}
</script>

<style scoped>
.dateCard {
  margin: 0px !important;
  color: black;
  padding: 0px 0px 15px 0px !important;
  border: 2px solid;
  box-shadow: unset !important;
  border-color: rgb(146, 141, 141) !important;
}

.v-btn--is-elevated{
    box-shadow: unset !important;
}

.dateClass{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 100;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left:3%;
}

.datesClass{
    width:15% !important;
    margin-left:5% !important;
    border-radius: 15px !important;
}

.mrgClass{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}

.dateStyle {
  margin-top: -18px !important;
  padding: 0px !important;
  font-size: 17px !important;
  font-weight:500 !important;
}

.active{
    color: #FFFFFF !important;
    width: 80px;
    height: 30px !important;
    background-color: #48ACEF !important;
    border: 0.5px solid #48ACEF !important;
    border-radius: 4px;
    font-weight:400;
    text-align:justify;
}

.font12 {
  font-size: 12px !important;
  padding: 0px 0px !important;
  font-weight: 300 !important;
  line-height: 18px !important;
}

.timingsicon2{
    width: 80px !important;
    height: 30px !important;
    background-color: #FFFFFF !important;
    border: 1px solid #48ACEF !important;
    border-radius: 4px;
    color:#48ACEF !important;
    font-weight:400;
    text-align:justify;
}

.font13 {
  font-size: 14px !important;
  padding: 0px 0px !important;
  font-weight:300 !important;
  line-height: 18px;
}


</style>

<style> 

.v-text-field--enclosed .v-input__append-inner{
    margin-top:10px !important;
}
.v-text-field--outlined>.v-input__control>.v-input__slot{
    min-height: 40px !important;
}

</style>